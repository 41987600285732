import type {UIMatch} from '@remix-run/react';
import {Link, Outlet, useMatches} from '@remix-run/react';

import type {RouteHandle} from '@/router-types';
import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';

type MainTabsProps = BaseComponentProps & {};

export function MainTabs(props: MainTabsProps = {}) {
  useSignals();
  const {className, children} = props;
  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];

  const rootTabMatch = matches.find((match) => match.handle?.getTabs?.(matches));
  if (!rootTabMatch) {
    return children;
  }
  const match = matches[matches.length - 1];
  const handle = match?.handle;
  if (!handle) {
    return null;
  }
  const {getTabs} = handle;
  const tabs = getTabs?.(matches);
  if (!tabs) {
    return children;
  }

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <div className="border-b border-gray-200 px-1 sm:px-4 sm:pl-6 sm:pr-2 lg:pl-8 lg:pr-4">
        <nav
          aria-label="Tabs"
          className="-mb-px flex gap-x-2 sm:gap-x-4 overflow-x-auto"
        >
          {tabs.map((tab) => {
            const to = rootTabMatch.pathname + tab.href;
            const isCurrent = to === location.pathname;
            return (
              <Link
                key={tab.name}
                to={to}
                aria-current={isCurrent ? 'page' : undefined}
                className={clsx(
                  isCurrent
                    ? 'border-livan-black/50 text-livan-black'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 px-3 sm:px-1 py-4 text-sm font-medium flex-col sm:flex-row sm:items-end',
                )}
              >
                <tab.icon
                  aria-hidden="true"
                  className={clsx(
                    isCurrent ? 'text-livan-black' : 'text-gray-400 group-hover:text-gray-500',
                    'sm:mr-2 size-6 sm:size-5',
                  )}
                />
                <h4 className="mt-1 sm:mt-0">{tab.name}</h4>
              </Link>
            );
          })}
        </nav>
      </div>
      <div className="flex flex-col flex-1">{children}</div>
    </div>
  );
}
