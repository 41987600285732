import LivanLink from '@/components/core/LivanLink';
import {
  boardsSignalsByOrgIdStore,
  currentUserIsAdminSignal,
  userOrgsSignal,
} from '@/context/UserContext';
import {
  Cog6ToothIcon,
  HandThumbUpIcon,
  PlusIcon,
  Squares2X2Icon,
  TruckIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import {useLocation} from '@remix-run/react';
import type {ComponentProps, MouseEventHandler} from 'react';

import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import type {MenuItems} from '@headlessui/react';
import {Transition} from '@headlessui/react';
import type {Board} from '@/model/board/types';
import type {OrgId} from '@/model/org/types';
import {StylistRole} from '@/model/role/default';
import React, {useMemo} from 'react';
import DropdownMenu, {type DropdownMenuItem} from '@/components/core/DropdownMenu';

export type MainNavItem = {
  id: string;
  name: string;
  path?: string;
  getSubItems?: () => SubNavItem[] | undefined;
  getDropdownItems?: () => DropdownMenuItem[] | undefined;
  Icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
};

type SubNavItem = Pick<MainNavItem, 'id' | 'name' | 'path' | 'Icon'>;

interface MainNavSectionProps {
  className?: string;
  items: MainNavItem[];
  onItemClick?: MouseEventHandler;
}

export function MainNavSection(props: MainNavSectionProps) {
  const {items, className, onItemClick} = props;
  return (
    <ul
      role="menu"
      className={clsx('-mx-2 space-y-1', className)}
    >
      {items.map((item) => {
        return (
          <MainNavSectionItem
            key={item.id}
            item={item}
            onClick={onItemClick}
          />
        );
      })}
    </ul>
  );
}

interface MainNavSectionItemProps {
  item: MainNavItem;
  onClick?: MouseEventHandler;
}

function MainNavSectionItem(props: MainNavSectionItemProps) {
  useSignals();
  const location = useLocation();
  const {item, onClick} = props;
  const {id, name, path, Icon, getSubItems, getDropdownItems} = item;
  const isCurrent = item.path === location.pathname;
  const isParentCurrent = !!item.path && location.pathname.startsWith(item.path);
  const subItems = getSubItems?.() || [];

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const dropdownItems = getDropdownItems?.() || [];
    if (onClick) {
      return dropdownItems.map((item) => {
        if (item.isDivider) {
          return {isDivider: true};
        }
        return {
          ...item,
          onClick,
        };
      });
    }
    return dropdownItems;
  }, [getDropdownItems, onClick]);

  const body = (
    <div className="group flex gap-x-3 p-2 font-semibold truncate w-full">
      {Icon ? (
        <Icon
          aria-hidden="true"
          className="size-6 shrink-0"
        />
      ) : (
        <span className="flex size-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-900 font-medium text-gray-400 group-hover:text-white">
          {name[0]}
        </span>
      )}
      <span className="truncate">{name}</span>
    </div>
  );

  const buttonClassName = clsx(
    isCurrent ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-900 hover:text-white',
    'flex truncate rounded-md w-full',
  );

  const anchor: ComponentProps<typeof MenuItems>['anchor'] = useMemo(() => {
    return {to: 'top', gap: 22};
  }, []);
  return (
    <li key={id}>
      {dropdownItems.length ? (
        <DropdownMenu
          className={buttonClassName}
          truncate
          fullWidth
          items={dropdownItems}
          anchor={anchor}
          buttonRole="menuitem"
        >
          {body}
        </DropdownMenu>
      ) : (
        <LivanLink
          type="unstyled"
          role="menuitem"
          to={path}
          className={buttonClassName}
          onClick={onClick}
        >
          {body}
        </LivanLink>
      )}
      <Transition show={isParentCurrent}>
        {subItems && (
          <ul
            style={{
              // TODO for some reason, headless UI does not animate out properly with fixed style attr..?
              // potentially related? https://github.com/tailwindlabs/headlessui/issues/3479
              height: isParentCurrent ? subItems.length * 40 : 0,
            }}
            className={clsx(
              'ml-3',
              'transition-[height] duration-300 overflow-y-hidden',
              'data-[closed]:!h-0',
            )}
          >
            {subItems.map((subItem) => {
              const {id, name, path, Icon} = subItem;
              const isCurrent = path === location.pathname;
              const Component = path ? LivanLink : 'div';
              const IconComponent = Icon || 'div';
              return (
                <li key={id}>
                  <Component
                    type="unstyled"
                    role="menuitem"
                    to={path}
                    className={clsx(
                      path
                        ? isCurrent
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-400 hover:bg-gray-900 hover:text-white'
                        : 'text-gray-400',
                      'group flex gap-x-3 rounded-md p-2 font-semibold leading-6 items-center',
                    )}
                    onClick={path ? onClick : undefined}
                  >
                    <IconComponent
                      aria-hidden="true"
                      className="size-3 shrink-0"
                    />
                    <span className="truncate">{name}</span>
                  </Component>
                </li>
              );
            })}
          </ul>
        )}
      </Transition>
    </li>
  );
}

function convertBoardToSubItem(orgId: OrgId, board: Board) {
  return {
    id: board.id,
    name: board.name,
    path: `/orgs/${orgId}/boards/${board.id}`,
    Icon: Squares2X2Icon,
  };
}

export function OrgNavSection(props) {
  useSignals();
  const {className} = props;
  const orgs = userOrgsSignal.value;
  if (orgs == undefined) {
    // user not logged in
    return null;
  }
  const items: MainNavItem[] = orgs.map((org) => {
    const {id, name} = org;

    const readOnly = !org?.rolePriority || org?.rolePriority < StylistRole.priority;

    return {
      id,
      name,
      path: `/orgs/${id}`,
      getSubItems() {
        const boardSignalsSignal = boardsSignalsByOrgIdStore.getById({id});
        if (boardSignalsSignal.value) {
          const currentUserIsAdmin = currentUserIsAdminSignal.value;
          const publishedBoards: Board[] = [];
          const unpublishedBoards: Board[] = [];
          for (let i = 0; i < boardSignalsSignal.value.length; i++) {
            const board = boardSignalsSignal.value[i].value;
            if (board.publishedAt) {
              publishedBoards.push(board);
            } else {
              unpublishedBoards.push(board);
            }
          }

          const subItems: MainNavItem[] = [];
          if (unpublishedBoards.length) {
            subItems.push(
              {
                id: `${id}-unpublished-header`,
                name: 'Unpublished Boards',
              },
              ...unpublishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          if (publishedBoards.length) {
            subItems.push(
              {
                id: `${id}-published-header`,
                name: 'Published Boards',
              },
              ...publishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          subItems.push(
            ...[
              {
                id: `${id}-shipments`,
                name: 'Shipments',
                path: `/orgs/${id}/shipments`,
                Icon: TruckIcon,
              },
              currentUserIsAdmin && {
                id: `${id}-deals`,
                name: 'Deals',
                path: `/orgs/${id}/deals`,
                Icon: HandThumbUpIcon,
              },
              {
                id: `${id}-members`,
                name: 'Members',
                path: `/orgs/${id}/members`,
                Icon: UserGroupIcon,
              },
              !readOnly && {
                id: `${id}-settings`,
                name: 'Settings',
                path: `/orgs/${id}/settings`,
                Icon: Cog6ToothIcon,
              },
            ].filter(Boolean),
          );
          return subItems;
        }
      },
    };
  });

  if (currentUserIsAdminSignal.value) {
    items.push({
      id: '£create', // use weird symbol to lower likelihood that these conflict
      name: 'Create an org',
      path: '/orgs/create',
      Icon: PlusIcon,
    });
  }

  return (
    <MainNavSection
      {...props}
      items={items}
    />
  );
}
