import ErrorLogger, {isSentryEnabled} from '@/ErrorLogger';
import icon48x48Url from '@/assets/images/icon-48x48.jpg';
import icon512x512Url from '@/assets/images/icon-512x512.png';
import SidebarLivanLogoSrc from '@/assets/images/logo-red-sm.png';
import type {DropdownMenuItem} from '@/components/core/DropdownMenu';
import DropdownMenu from '@/components/core/DropdownMenu';
import LivanImage from '@/components/core/LivanImage';
import StatusContext from '@/context/StatusContext';
import {currentUserIsAdminSignal, currentUserSignal, userOrgsSignal} from '@/context/UserContext';
import {Dialog, DialogBackdrop, DialogPanel, TransitionChild} from '@headlessui/react';
import {
  ArrowLeftEndOnRectangleIcon,
  Bars3Icon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  HomeIcon,
  UserCircleIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import type {LinksFunction, MetaFunction} from '@remix-run/node';
import type {ShouldRevalidateFunction, UIMatch} from '@remix-run/react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useMatches,
  useParams,
} from '@remix-run/react';
import {useCallback, useState} from 'react';

import {GlobalNavigator} from '@/api/GlobalNavigator';
import {
  SuspenseFallback,
  WithDeferredLoaderErrorHandling,
  WithLivanComponentWrapper,
} from '@/api/RemixUtils';
import {RootRevalidator} from '@/api/RootRevalidator';
import LivanErrorBoundary from '@/components/core/LivanErrorBoundary';
import {MainHeading} from '@/components/layout/MainHeading';
import {MainNavSection, OrgNavSection, type MainNavItem} from '@/components/layout/MainNav';
import {MainTabs} from '@/components/layout/MainTabs';
import ReconnectBanner from '@/components/layout/ReconnectBanner';
import ModalContext from '@/components/modal/ModalContext';
import SocketUpdateEventTypes from '@/events/SocketUpdateEvents';
import useWebSocketEvent from '@/events/useWebSocketEvent';
import type {RouteHandle} from '@/router-types';
import {GlobalRouterStateUpdater} from '@/state/router';
import '@/styles/tailwind.css';
import {trpc} from '@/utils/trpc';
import {initializeWebsocketSubscription} from '@/utils/websocketsTrpc';
import {useSignals} from '@preact/signals-react/runtime';
import {withSentry} from '@sentry/remix';
import Footer from '@/components/layout/Footer';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'icon',
      href: icon48x48Url,
      type: 'image/jpeg',
      sizes: '48x48',
    },
    {
      rel: 'icon',
      href: icon48x48Url,
      type: 'image/x-icon',
      sizes: '48x48',
    },
    isSentryEnabled && {
      rel: 'preconnect',
      href: 'https://ingest.sentry.io',
    },
    {
      rel: 'preconnect',
      href: 'https://cloudflareinsights.com',
    },
  ].filter(Boolean);
};

const mainNavItems: MainNavItem[] = [
  {id: 'home', name: 'Home', path: '/', Icon: HomeIcon},
  // {name: 'Board', path: '/board'},
  // {name: 'Orgs', path: '/orgs'},
];

const loggedInUserMenuItems: DropdownMenuItem[] = [
  // {name: 'Your Profile', path: '#'},
  // {name: 'Settings', path: '#'},
  {label: 'Log out', to: 'log-out', Icon: ArrowLeftEndOnRectangleIcon},
];

const loggedOutUserMenuItems: DropdownMenuItem[] = [
  {label: 'Log in', to: 'log-in', Icon: ArrowLeftEndOnRectangleIcon},
  {label: 'Sign up', to: 'sign-up', Icon: UserPlusIcon},
];

// let websocketSubscription: Subscription<{randomNumber: number}> | undefined;

export const clientLoader = WithDeferredLoaderErrorHandling(
  async function clientLoader(params) {
    const {user, orgs, isAdmin} = await trpc.session.get.query();
    if (user) {
      initializeWebsocketSubscription();
    }
    ErrorLogger.SetUser(user);
    currentUserSignal.value = user;
    currentUserIsAdminSignal.value = !!isAdmin;
    userOrgsSignal.value = orgs;
    return {user, orgs};
  },
  {
    ignoreUserFetch: true,
    allowsUnauthenticated: true,
  },
);

export function Layout({children}: {children: React.ReactNode}) {
  return (
    <html
      lang="en"
      className="h-full bg-white font-serif text-livan-black"
    >
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta
          name="description"
          content="Luxury fashion concierge service for bespoke styling. Access unique consignment options and curated sartorial choices."
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:title"
          content="Livan"
        />
        <meta
          property="og:description"
          content="Luxury fashion concierge service for bespoke styling. Access unique consignment options and curated sartorial choices."
        />
        <meta
          property="og:image"
          content={icon512x512Url}
        />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <div className="h-full">{children}</div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function RootContexts({children}: {children: React.ReactNode}) {
  useSignals();
  return (
    <>
      <GlobalNavigator />
      <GlobalRouterStateUpdater />
      <RootRevalidator />
      <ModalContext>
        <StatusContext>
          <ReconnectBanner />
          {children}
        </StatusContext>
      </ModalContext>
    </>
  );
}

function MainNavContextMenu() {
  useSignals();
  const params = useParams();
  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];

  const handle = matches[matches.length - 1].handle;
  if (!handle) {
    return null;
  }
  const {getMainNavContextMenuItems} = handle;
  const contextMenuItems = getMainNavContextMenuItems?.({params});
  if (!contextMenuItems?.length) {
    return null;
  }

  return (
    <>
      <div
        aria-hidden="true"
        className="h-6 w-px bg-livan-black/10 lg:hidden shrink-0"
      />
      <DropdownMenu
        className="MainNavContextMenuButton ml-1 md:ml-3"
        items={contextMenuItems}
      >
        <span className="sr-only">Open context menu</span>
        <EllipsisVerticalIcon className="size-8" />
      </DropdownMenu>
    </>
  );
}

function FullScreenCenteredLayout(params) {
  return (
    <div className="flex flex-col items-center size-full p-2 bg-livan-black/80">
      <main className="p-3 sm:p-5 flex flex-1 items-center w-full sm:w-[640px]">
        <div className="w-full rounded-lg bg-white shadow">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export function Main() {
  useSignals();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const closeSidebar = useCallback(
    function () {
      setSidebarOpen(false);
    },
    [setSidebarOpen],
  );
  const openSidebar = useCallback(
    function () {
      setSidebarOpen(true);
    },
    [setSidebarOpen],
  );
  const user = currentUserSignal.value;
  const userMenuItems = user ? loggedInUserMenuItems : loggedOutUserMenuItems;

  const matches = useMatches() as UIMatch<unknown, RouteHandle | undefined>[];

  const handle = matches[matches.length - 1].handle;
  if (handle?.layout === 'full-screen-centered') {
    return <FullScreenCenteredLayout />;
  }

  const bottomNavItems: MainNavItem[] = user
    ? [
        {
          id: 'settings',
          name: user.name,
          path: '/settings',
          Icon: UserCircleIcon,
          getDropdownItems() {
            return [
              {
                label: 'Settings',
                to: '/settings',
                Icon: Cog6ToothIcon,
              },
              {
                isDivider: true,
              },
              {
                label: 'Log out',
                to: '/log-out',
                Icon: ArrowLeftEndOnRectangleIcon,
              },
            ];
          },
        },
      ]
    : [
        {
          id: 'log-in',
          name: 'Log in',
          path: '/log-in',
          Icon: ArrowLeftEndOnRectangleIcon,
        },
        {
          id: 'sign-up',
          name: 'Sign up',
          path: '/sign-up',
          Icon: UserPlusIcon,
        },
      ];

  return (
    <>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-30 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-livan-black/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="size-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="MobileLeftNav flex grow flex-col gap-y-3 overflow-y-auto bg-livan-black px-6 pb-4 ring-1 ring-white/10">
              <div className="flex py-3 min-h-16 shrink-0 items-center">
                <LivanImage
                  className="w-full px-6"
                  alt="livan"
                  src={SidebarLivanLogoSrc}
                />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <MainNavSection
                      items={mainNavItems}
                      onItemClick={closeSidebar}
                    />
                  </li>
                  {user && (
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Your orgs</div>
                      <OrgNavSection
                        className="mt-2"
                        onItemClick={closeSidebar}
                      />
                    </li>
                  )}
                  <li className="mt-auto">
                    <MainNavSection
                      items={bottomNavItems}
                      onItemClick={closeSidebar}
                    />
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="DesktopLeftNav hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-3 overflow-y-auto bg-livan-black px-6 pb-4">
          <div className="flex py-3 min-h-16 shrink-0 items-center">
            <LivanImage
              className="w-full px-6"
              alt="livan"
              src={SidebarLivanLogoSrc}
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <MainNavSection items={mainNavItems} />
              </li>
              {user && (
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your orgs</div>
                  <OrgNavSection className="mt-2" />
                </li>
              )}
              <li className="mt-auto">
                <MainNavSection items={bottomNavItems} />
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72 h-full flex flex-col overflow-y-auto">
        <div className="sticky top-0 z-30 flex min-h-16 py-3 shrink-0 items-center gap-x-3 md:gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:pl-6 sm:pr-2 lg:pl-8 lg:pr-4">
          <button
            type="button"
            onClick={openSidebar}
            className="MobileMainNavMenuButton -m-2.5 p-2.5 text-gray-700 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon
              aria-hidden="true"
              className="size-6"
            />
          </button>
          {/* Separator */}
          <div
            aria-hidden="true"
            className="h-6 w-px bg-livan-black/10 lg:hidden shrink-0"
          />
          <MainHeading className="flex-1" />
          {/*              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon
                    aria-hidden="true"
                    className="h-6 w-6"
                  />
                </button>
              </div>
*/}{' '}
          <MainNavContextMenu />
        </div>

        <MainTabs className="h-full">
          <main className="p-4 sm:p-6 lg:px-8 flex flex-col flex-1">
            <div className="flex-1">
              <Outlet />
            </div>
            <Footer />
          </main>
        </MainTabs>
      </div>
    </>
  );
}

function App(props, {loaderData}) {
  // TODO fix all of the re-renders!!
  // TODO fix all of the re-renders!!
  // TODO fix all of the re-renders!!

  useWebSocketEvent(SocketUpdateEventTypes.Org.Created, (org) => {
    userOrgsSignal.value!.push(org);
  });

  useWebSocketEvent(SocketUpdateEventTypes.Org.Updated, (org) => {
    const orgId = org.id;
    const index = userOrgsSignal.value!.findIndex((org) => {
      return org.id === orgId;
    });
    const userOrg = userOrgsSignal.value![index];
    userOrgsSignal.value!.splice(index, 1, {
      ...userOrg,
      ...org,
    });
    userOrgsSignal.value = [...userOrgsSignal.value!];
  });

  useWebSocketEvent(SocketUpdateEventTypes.Org.Deleted, ({id}) => {
    userOrgsSignal.value = userOrgsSignal.value!.filter((org) => org.id !== id);
  });

  return (
    <RootContexts>
      <Main />
    </RootContexts>
  );
}

export default WithLivanComponentWrapper({
  clientLoader,
  Component: withSentry(App),
});

export function HydrateFallback() {
  return <SuspenseFallback centered />;
}

export const ErrorBoundary = LivanErrorBoundary;

export const meta: MetaFunction = (...args) => {
  return [
    {
      title: 'Livan',
    },
  ];
};

export const shouldRevalidate: ShouldRevalidateFunction = (args) => {
  // never re-fetch the user from this route.
  // see RootRevalidator if you need to manually do this
  return false;
};
